import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance as AxiosInstanceType } from "axios";

class AxiosInstance {
    instance: AxiosInstanceType;

    constructor(baseUrl: string, config?: AxiosRequestConfig) {
        this.instance = axios.create({
            baseURL: baseUrl,
            timeout: 1000 * 20,
            withCredentials: true,
            ...config,
        })

        this.instance.interceptors.response.use(
            (response: AxiosResponse) => response,
            error => Promise.reject(error.response ? { status: error.response.status, ...error.response.data } : error),
        );
    }

    get = async <TResponse>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>> => {
        return await this.instance.get<TResponse>(url, config);
    };

    post = async <TPayload, UResponse = {}>(url: string, data: TPayload, config?: AxiosRequestConfig): Promise<AxiosResponse<UResponse>> => {
        return await this.instance.post<UResponse>(url, data, config);
    };

    put = async <TPayload, UResponse = {}>(url: string, data: TPayload, config?: AxiosRequestConfig): Promise<AxiosResponse<UResponse>> => {
        return await this.instance.put<UResponse>(url, data, config);
    };

    patch = async <TPayload, UResponse = {}>(url: string, data: TPayload, config?: AxiosRequestConfig): Promise<AxiosResponse<UResponse>> => {
        return await this.instance.patch<UResponse>(url, data, config);
    };

    delete = async <TResponse>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<TResponse>> => {
        return await this.instance.delete<TResponse>(url, config);
    };
}

export default AxiosInstance;