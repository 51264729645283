import { useContext, useState } from "react";
import classNames from "classnames/bind";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useGameStore } from "src/components/game/hooks/useGameStore";
import styles from "./assets/styles.scss";
import Races from "src/components/stats-table/Races/Races";
import config from "src/config";
import AllBetsHighRollersRow from "src/components/stats-table/AllBetsHighRollersRow";
import ConnectWalletButton from "src/components/connect-wallet/ConnectWalletButton";
import MyBetsRow from "src/components/stats-table/MyBetsRow";
import { axiosInstance } from "src/helpers/apiClient";
import GameCodeContext from "src/containers/Games/GameCodeContext";
import TableWrapper from "src/components/table-wrapper/TableWrapper";
import { IGameHistoryResponse } from "src/api/responses/game-history";

const cx = classNames.bind(styles);

async function getMyBets(gameCode: string) {
    const { data: { data } } = await axiosInstance.get<IGameHistoryResponse>("/v1/mypage/gamehistory", {
        params: { asset: config.asset, game_code: gameCode, count: 15 },
    });
    return data.filter(({ game_result: gameResult }) => !!gameResult);
}

const tabs = ["MY BETS", "ALL BETS", "HIGH ROLLERS", "RACES"] as const;

export default function StatsTable() {
    const gameCode = useContext(GameCodeContext);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const user = useSelector(state => state.auth.user);
    const [selectedTab, setSelectedTab] = useState(1);
    const { data: myBets = [], isLoading: myBetsLoading } = useQuery(["mybets", gameCode], () => getMyBets(gameCode || ""), {
        enabled: selectedTab === 0 && !!user,
        retry: false,
    });

    const allBets = useGameStore(state => state.allBets);
    const highRollers = useGameStore(state => state.highRollers);

    function getContent() {
        if ([0, 1, 2].includes(selectedTab)) {
            // FIXME add types
            let tableRows;
            let columns;
            let emptyPlaceholder;

            if ([1, 2].includes(selectedTab)) {
                tableRows = (selectedTab === 1 ? allBets : highRollers).map((result, index) => (
                    <AllBetsHighRollersRow
                        result={result}
                        key={`${selectedTab}${result.asset}${result.game}${result.player_id}`}
                        className={index % 2 === 0 ? "even" : "odd"}
                    />
                ));

                columns = [
                    { label: "Game" },
                    { label: "User" },
                    { label: "Bet", hideMobile: true },
                    { label: "Multiplier", hideMobile: true },
                    { label: "Pick", hideMobile: true },
                    { label: "Result", hideMobile: true },
                    { label: "Payout (BSV)" },
                ];
            } else {
                columns = [
                    { label: "Round ID" },
                    { label: "Bet" },
                    { label: "Multiplier", hideMobile: true },
                    { label: "My Pick", hideMobile: true },
                    { label: "Result", hideMobile: true },
                    { label: "Payout (BSV)" },
                ];

                if (!user) {
                    emptyPlaceholder = <ConnectWalletButton />;
                } else if (!myBets.length) {
                    emptyPlaceholder = "You do not have any history";
                } else {
                    tableRows = myBets.map((result, index) => (
                        <MyBetsRow
                            key={`${result.asset}${result.game}${result.player_id}`}
                            result={result}
                            gameCode={gameCode || ""}
                            className={index % 2 === 0 ? "even" : "odd"}
                        />
                    ));
                }
            }

            return (
                <TableWrapper
                    columns={columns}
                    isLoading={selectedTab === 0 && myBetsLoading}
                    className={cx("table-wrapper", {
                        "all-bets": selectedTab === 1 || selectedTab === 2,
                    })}
                    emptyPlaceholder={emptyPlaceholder}
                >
                    {tableRows}
                </TableWrapper>
            );
        }

        return <Races />;
    }

    return (
        <div className={cx("stats-table")}>
            <div className={cx("header")}>
                {tabs.map((label, index) => (
                    <button
                        key={label}
                        type="button"
                        className={cx({ active: selectedTab === index })}
                        onClick={() => setSelectedTab(index)}
                    >
                        {label}
                    </button>
                ))}
            </div>
            {getContent()}
        </div>
    );
}
