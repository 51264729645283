import { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import { useSelector } from "react-redux";
import styles from "./styles.scss";
import GameBalance from "./GameBalance/GameBalance";
import Cashout from "./Cashout/Cashout";
import useTableDeposit from "./GameBalance/useTableDeposit";

import IconArrowDown from "src/components/game/assets/images/arrow-down.svg?inline";
import { useMobileOnly } from "src/helpers/responsive";
import GameCodeContext from "src/containers/Games/GameCodeContext";
import useAppStore from "src/useAppStore";
import useClickOutside from "src/helpers/useClickOutside";
import usePageScroll from "src/helpers/usePageScroll";
import useLocationChange from "src/helpers/useLocationChange";
import { assetIcons } from "src/config";
import BigNumber from "bignumber.js";

const cx = classNames.bind(styles);

const decimalPoints = 8;
const staticNoMoneyText = "0.00000000";

interface IMyPointsProps {
    reverse?: boolean;
}

interface IColorChange {
    color: string | null;
    asset: string | null;
}

export default function MyPoints({ reverse = false }: IMyPointsProps) {
    const popupRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const isMobileOnly = useMobileOnly();
    const gameCode = useContext(GameCodeContext);
    const [isOpen, setIsOpen] = useState(false);
    const { selected, balance, isLoading } = useAppStore(state => state.asset);
    const isLoggedIn = useSelector((state: any) => !!state.auth.user);
    const [colorChange, setColorChange] = useState<IColorChange>({ color: null, asset: null });
    const maintenance = useSelector(
        (state: any) => state.info?.maintenance.wallet.find(({ name }: { name: string }) => name === "deposit")?.is_maintenance,
    );
    const { depositBalance } = useTableDeposit();

    useClickOutside(() => {
        setIsOpen(false);
    }, [popupRef, buttonRef]);
    usePageScroll(() => setIsOpen(false));
    useLocationChange(() => setIsOpen(false));

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (colorChange.color) {
            timeoutId = setTimeout(() => setColorChange({ color: null, asset: null }), 1500);
        }
        return () => clearTimeout(timeoutId);
    }, [colorChange]);

    useEffect(() => {
        useAppStore.getState().getAssetBalance();
        const intervalId = setInterval(() => {
            useAppStore.getState().getAssetBalance();
        }, 1000 * 60);

        const unsubscribe = useAppStore.subscribe(
            (asset: any, prevAsset: any) => {
                if (!prevAsset.isLoading) {
                    const { balance: newBalance } = asset;
                    const { balance: prevBalance } = prevAsset;
                    if (!newBalance.BSV.isEqualTo(prevBalance.BSV)) {
                        setColorChange({ color: newBalance.BSV.isGreaterThan(prevBalance.BSV) ? "green" : "red", asset: "BSV" });
                    } else if (!newBalance.PGP.isEqualTo(prevBalance.PGP)) {
                        setColorChange({ color: newBalance.PGP.isGreaterThan(prevBalance.PGP) ? "green" : "red", asset: "PGP" });
                    }
                }
            },
            (state: any) => state.asset,
        );

        return () => {
            unsubscribe();
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            const savedAsset = localStorage.getItem("asset");
            if (savedAsset) {
                useAppStore.getState().setSelectedAsset(savedAsset);
            }
        }
    }, [isLoggedIn]);

    function setSelectedAsset(asset: string) {
        useAppStore.getState().setSelectedAsset(asset);
        localStorage.setItem("asset", asset);
        setIsOpen(false);
    }

    function getButtonContent() {
        if (!isLoading) {
            let Icon = assetIcons[selected];
            let balanceContent: BigNumber | undefined = balance[selected];

            if (["table", "slots", "blackjack"].includes(gameCode || "")) {
                balanceContent = depositBalance;
                Icon = assetIcons.BSV;
            }

            return (
                <>
                    <span className={cx("bold", selected === colorChange.asset && colorChange.color)}>
                        {!!balanceContent ? balanceContent?.toFixed(decimalPoints) : staticNoMoneyText}
                    </span>
                    <Icon className={cx("coin")} />
                    {!isMobileOnly && <IconArrowDown className={cx("arrow", { rotate: isOpen })} />}
                </>
            );
        }
    }

    return (
        <div className={cx("my-points")}>
            <button
                type="button"
                className={cx("my-points-button", { skeleton: isLoading })}
                ref={buttonRef}
                onClick={() => setIsOpen(!isOpen)}
                title={!!balance[selected] ? balance[selected]?.toFixed(decimalPoints) : staticNoMoneyText}
            >
                {getButtonContent()}
            </button>
            <div className={cx("points-popup", { open: isOpen, reverse })} ref={popupRef} title={balance.BSV.toFixed()}>
                <button type="button" className={cx("bold")} onClick={() => setSelectedAsset("BSV")}>
                    <span className={cx("bold")}>{balance?.BSV?.toFixed(decimalPoints)}</span>
                    {assetIcons.BSV({})}
                    BSV
                </button>
                <button
                    type="button"
                    className={cx("bold")}
                    onClick={() => setSelectedAsset("PGP")}
                    title={balance?.PGP?.toFixed()}
                >
                    <span className={cx("bold")}>{balance?.PGP?.toFixed(decimalPoints)}</span>
                    {assetIcons.PGP({})}
                    PGP
                </button>
                <span className={cx("small", "label")}>Game Balance</span>
                {maintenance === "Y" ? (
                    <span className={cx("maintenance")}>Game Balance feature is under maintenance. Please try again later.</span>
                ) : (
                    <>
                        <GameBalance />
                        <Cashout />
                    </>
                )}
            </div>
        </div>
    );
}


