import { useQuery } from "react-query";
import BetDetailPresenter from "src/components/stats-table/BetDetailPresenter";
import { axiosInstance } from "src/helpers/apiClient";
import { IBetsDetailsResponse } from "src/api/responses/bets";

interface IBetDetailContainerProps {
    isOpen: boolean,
    onClose: () => void,
    betAmount: number,
    gameCode: string,
    multiplier: string | number,
    asset: string,
    isWin: boolean,
    playerId: number,
}

async function getBetDetail(asset: string, gameCode: string, playerId: number) {
    const { data: { data } } = await axiosInstance.get<IBetsDetailsResponse>("/v1/bets/details", {
        params: {
            asset,
            game_code: gameCode,
            player_id: playerId,
        },
    });
    return data?.[0];
}

export default function BetDetailContainer({
    isOpen,
    onClose,
    betAmount,
    gameCode,
    multiplier,
    playerId,
    isWin,
    asset,
}: IBetDetailContainerProps) {
    const { data: betDetails, isLoading } = useQuery(["betDetails", gameCode], () => getBetDetail(asset, gameCode, playerId), {
        enabled: true,
    });

    if (!betDetails) {
        return null;
    }

    return (
        <BetDetailPresenter
            isOpen={isOpen}
            onClose={onClose}
            betAmount={betAmount}
            gameCode={gameCode}
            multiplier={multiplier}
            isWin={isWin}
            asset={asset}
            betDetails={betDetails}
        />
    );
}


