import crownIcon from "./assets/Hero_Crown@2x.png";
import chipIcon from "./assets/Hero_Chip@2x.png";
import {
    StyledFlexContainer,
    StyledImageContainer,
    StyledSpan,
    StyledProgressBarContainer, StyledProgressLoader,
} from "src/affiliate/components/commission-plans/assets/styles/commission-rate-card.styled";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import { H3, H6 } from "src/affiliate/styled/typography.styled";

interface ICommissionCardProps {
    tierName: string
    commissionRate: number
    referrals: number
    requiredReferrals: number
    requiredWagered: number
    wagered: number
}

const CommissionRateCard = ({
    tierName, commissionRate, referrals, requiredReferrals, requiredWagered, wagered,
}: ICommissionCardProps) => (
    <StyledFlexContainer flexDirection="column" padding="24px 29px 24px 29px">
        <StyledImageContainer width="51px" top="-33px" left="0">
            <img src={crownIcon} alt="crown-icon" />
        </StyledImageContainer>
        <StyledImageContainer width="67px" bottom="-52px" right="0">
            <img src={chipIcon} alt="chip-icon" />
        </StyledImageContainer>
        <FlexContainer flexWrap width="100%" justifyContent="space-between">
            <FlexContainer flexDirection="column" alignItems="center">
                <StyledSpan className="xsmall">Level</StyledSpan>
                <H3 margin="4px 0 0 0">{tierName}</H3>
            </FlexContainer>
            <FlexContainer flexDirection="column" alignItems="center">
                <StyledSpan className="xsmall">Commission Rate</StyledSpan>
                <H3 margin="4px 0 0 0">{commissionRate * 100}%</H3>
            </FlexContainer>
            <FlexContainer flexDirection="column" alignItems="center">
                <StyledSpan className="xsmall">Referrals</StyledSpan>
                <H3 margin="4px 0 0 0">{requiredReferrals ? `${referrals}/${requiredReferrals}` : referrals}</H3>
            </FlexContainer>
        </FlexContainer>
        <FlexContainer flexDirection="column">
            <FlexContainer margin="28px 0 0 0" justifyContent="space-between" alignItems="center">
                <StyledSpan className="xsmall">Wagered</StyledSpan>
                <H6 margin="0" color="#fff">
                    {wagered === 0 ? wagered : wagered.toFixed(2)} / {requiredWagered.toFixed(2)}
                </H6>
            </FlexContainer>
            <StyledProgressBarContainer>
                <StyledProgressLoader loaderWidth={wagered >= requiredWagered ? 100 : (wagered / requiredWagered) * 100} />
            </StyledProgressBarContainer>
        </FlexContainer>
    </StyledFlexContainer>
);

export default CommissionRateCard;
