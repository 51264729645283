import React, { useRef, useState } from "react";
import classNames from "classnames/bind";
import ConnectWallet from "src/components/connect-wallet/ConnectWallet";
import IconWallet from "./assets/images/connect-wallet.svg?inline";
import styles from "./assets/styles.scss";

const cx = classNames.bind(styles);

export default function ConnectWalletButton() {
    const [showConnectWallet, setShowConnectWallet] = useState(false);
    const connectWalletRef = useRef<HTMLButtonElement | null>(null);

    return (
        <>
            <button
                className={cx("wallet-button")}
                type="button"
                onClick={() => setShowConnectWallet(!showConnectWallet)}
                ref={connectWalletRef}
            >
                <IconWallet />
                <span className="hide-mobile small">Connect Wallet</span>
            </button>
            <ConnectWallet
                isOpen={showConnectWallet}
                onClose={() => setShowConnectWallet(false)}
                buttonRefs={[connectWalletRef]}
            />
        </>
    );
}

