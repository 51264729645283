import classNames from "classnames/bind";
import { useInfiniteQuery, QueryFunctionContext } from "react-query";
import styles from "./styles.scss";
import { axiosInstance } from "src/helpers/apiClient";
import TableWrapper from "src/components/table-wrapper/TableWrapper";
import config from "src/config";
import { IDepositHistoryResponse } from "src/affiliate/hooks/deposit-history";

const cx = classNames.bind(styles);

interface ITransactionsProps {
    task: string;
    status: string
}

async function getTransactions(
    task: string,
    status: string,
    { pageParam = null }: { pageParam?: number | null }
) {
    const { data: { data } } = await axiosInstance.get<IDepositHistoryResponse>("/v1/deposit/history", {
        params: {
            asset: config.asset,
            count: 30,
            offset: pageParam,
            task,
            status,
        },
    })

    return data
}

export default function TransactionsTable({ task, status }: ITransactionsProps) {
    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetching,
        isLoading,
    } = useInfiniteQuery(
        ["mytransactions", task, status],
        (context: QueryFunctionContext) => getTransactions(task, status, context),
        {
            getNextPageParam: lastGroup => {
                const lastElement = lastGroup[lastGroup.length - 1];
                return lastElement && lastElement.timestamp;
            },
        }
    );

    const flatData = data.pages.flat();

    const columns = [{ label: "Time" }, { label: "Type" }, { label: "Status" }, { label: "Amount (BSV)" }];
    return (
        <TableWrapper
            isLoading={isLoading}
            columns={columns}
            className={cx("history-table")}
            infiniteScrollProps={{
                loadMore: () => fetchNextPage(),
                hasMore: !isFetching && hasNextPage,
            }}
            emptyPlaceholder={!flatData.length ? "You do not have any transactions" : ""}
        >
            {flatData.map(({ txid, status: txStatus, timestamp, amount, task: type }) => {
                const date = new Date(timestamp);
                const dateString = date.toLocaleDateString("en-GB", {
                    timeZone: "UTC",
                    year: "numeric",
                    day: "2-digit",
                    month: "2-digit",
                });
                const timeString = date.toLocaleTimeString("en-GB", {
                    timeZone: "UTC",
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                });

                return (
                    <tr key={txid} className={cx("small")}>
                        <td>{`${dateString} ${timeString}`}</td>
                        <td>{type === "DEPOSIT" ? "Top-Up" : "Cashout"}</td>
                        <td>{txStatus[0] + txStatus.slice(1).toLowerCase()}</td>
                        <td className={cx("bold", txStatus.toLowerCase())}>{amount}</td>
                    </tr>
                );
            })}
        </TableWrapper>
    );
}
