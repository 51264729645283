import { useQuery } from "react-query";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import styles from "./assets/styles.scss";
import Img1st from "../Races/assets/images/1st.png";
import Img2nd from "../Races/assets/images/2nd.png";
import Img3rd from "../Races/assets/images/3rd.png";
import { axiosInstance } from "src/helpers/apiClient";
import { games } from "src/games/config";
import { ICommonTopRankersResponse } from "src/api/responses/leaderboard";

const cx = classNames.bind(styles);

async function getLeaderboard() {
    const { data: { data: { top_winners, big_winners, asset_dollar_price } } } = (await axiosInstance.get<ICommonTopRankersResponse>("/v1/common/toprankers"));

    return {
        topWinners: top_winners || [],
        bigWinners: big_winners || [],
        assetDollarPrice: asset_dollar_price || {},
    }
}

const rankingImages = { 1: Img1st, 2: Img2nd, 3: Img3rd };

export default function Leaderboard() {
    const { data, isLoading } = useQuery("leaderboard", getLeaderboard);

    const dollarPrice = new BigNumber(data?.assetDollarPrice.price || 0);

    function getContent() {
        if (!isLoading) {
            return (
                <div className={cx("inner-content")}>
                    <h4>Top Winners</h4>
                    {data?.topWinners.map(({ nickname, profile_image_url: profilePic, amount }, index) => (
                        <div className={cx("row")} key={`topwinners-${index}`}>
                            <div className={cx("badge")}>
                                <img src={profilePic} alt="Profile pic" />
                                <span className={cx("small")}>{nickname.length > 10 ? `${nickname.slice(0, 10)}...` : nickname}</span>
                            </div>
                            <div>
                                <img src={rankingImages[index + 1]} alt={`rank-${index}`} />
                            </div>
                            <span className={cx("xlarge", "bold", "yellow")}>${dollarPrice.multipliedBy(amount).toFixed(0)}</span>
                        </div>
                    ))}
                    <h4>Big Wins</h4>
                    {data?.bigWinners.map(
                        ({
                            nickname, profile_image_url: profilePic, amount, game_code: gameCode, game_name: gameName,
                        }, index) => (
                            <div className={cx("row")} key={`bigwins-${index}`}>
                                <div className={cx("badge")}>
                                    <img src={profilePic} alt="Profile pic" />
                                    <span className={cx("small")}>{nickname.length > 10 ? `${nickname.slice(0, 10)}...` : nickname}</span>
                                </div>
                                <Link to={`/games/${games[gameCode]?.urlName || gameCode}`}>{games[gameCode]?.label || gameName}</Link>
                                <span className={cx("xlarge", "bold")}>${dollarPrice.multipliedBy(amount).toFixed(0)}</span>
                            </div>
                        )
                    )}
                </div>
            );
        }
    }

    return <div className={cx("leaderboard", { skeleton: isLoading })}>{getContent()}</div>;
}
