import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { withCookies, Cookies } from "react-cookie";
import PropTypes, { instanceOf } from "prop-types";
import { withRouter } from "react-router";
import { renderRoutes } from "react-router-config";
import { setMaintenance as _setMaintenance, getAccessAvailable as _getAccessAvailable } from "src/redux/modules/info";
import { load as _loadUser, setMoneyButtonUserIdHash as _setMoneyButtonUserIdHash } from "src/redux/modules/auth";
import { load as _loadAffiliate } from "src/redux/modules/affiliate";
import { getWallets as _getWallets } from "src/redux/modules/wallets";
import Toast from "src/components/toast/Toast";
import config from "src/config";
import { COOKIE } from "src/utils/defines";
import Maintenance from "static/Maintenance";
import "../../assets/css/main.css";
import "../../assets/scss/style.scss";
import tidio from "helpers/tidio";
import apiClient from "helpers/apiClient";
import useAppStore from "useAppStore";

@connect(
    state => ({
        maintenance: state?.info?.maintenance || {},
        store: state,
    }),
    {
        setMaintenance: _setMaintenance,
        getAccessAvailable: _getAccessAvailable,
        loadUser: _loadUser,
        loadAffiliate: _loadAffiliate,
        getWallets: _getWallets,
        setMoneyButtonUserIdHash: _setMoneyButtonUserIdHash,
    },
)
@withCookies
@withRouter
class App extends Component {
    static propTypes = {
        setMaintenance: PropTypes.func.isRequired,
        getAccessAvailable: PropTypes.func.isRequired,
        loadUser: PropTypes.func.isRequired,
        loadAffiliate: PropTypes.func.isRequired,
        getWallets: PropTypes.func.isRequired,
        setMoneyButtonUserIdHash: PropTypes.func.isRequired,
        route: PropTypes.objectOf(PropTypes.any).isRequired,
        location: PropTypes.objectOf(PropTypes.any).isRequired,
        cookies: instanceOf(Cookies).isRequired,
        maintenance: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        const { loadUser, loadAffiliate, getWallets } = this.props;

        loadUser()
            .then(() => {
                getWallets();
                loadAffiliate();
            })
            .catch(() => {
                useAppStore.getState().setAssetIsLoading(false);
            });
        this.last = new Date();
        this.sleepMode = false;
        if (!window.__PRERENDER_INJECTED) {
            tidio();
        }
    }

    componentDidMount() {
        const { getAccessAvailable } = this.props;

        getAccessAvailable();

        useAppStore.getState().socket.on("MAINTENANCE", data => {
            this.updateMaintenanceSocket(data);
        });

        this.loadMoneyButtonUserIdHash();

        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible" && this.sleepMode) {
                // window.location.reload();
                console.log("reload!");
            }
        };

        this.checkSleepMode = setInterval(() => {
            const now = new Date();
            if (now.getTime() - this.last.getTime() > 3000) {
                this.sleepMode = true;
                clearInterval(this.checkSleepMode);
            }
            this.last = now;
        }, 1000);

        const { location, cookies } = this.props;
        const params = new URLSearchParams(location?.search).get("c");

        if (params && params !== cookies.get("pg.campaign")) {
            cookies.set("pg.campaign", params, { maxAge: 60 * 60 * 24 * 30 });
            apiClient()
                .post("/affiliate/visit", {
                    campaign_url: `${window.origin}/?c=${params}`,
                })
                .then(res => console.log(res))
                .catch(e => console.log(e));
        }
        // don't hide the placeholder in prerender, used to deal with FOUC
        if (!window.__PRERENDER_INJECTED) {
            const loadPlaceholder = document.getElementById("initial-load-placeholder");
            loadPlaceholder.setAttribute("style", "display:none;");
            // show content
            // document.getElementById('react-root').style.removeProperty('display');
        }
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;

        if (location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    loadMoneyButtonUserIdHash() {
        const { setMoneyButtonUserIdHash } = this.props;
        const savedHashId = localStorage.getItem(COOKIE._MONEY_BUTTON_USER_ID_HASH);
        if (savedHashId) {
            setMoneyButtonUserIdHash(savedHashId);
        }
    }

    updateMaintenanceSocket(nextData) {
        const { setMaintenance } = this.props;
        setMaintenance(nextData);
    }

    render() {
        const { route, maintenance } = this.props;

        if (maintenance.code === "0" && maintenance.site[0].is_maintenance === "Y" && process.env.IGNORE_MAINTENANCE !== "true") {
            return <Maintenance site />;
        }
        return (
            <div>
                <Helmet {...config.app.head} />
                {renderRoutes(route.routes)}
                <Toast />
            </div>
        );
    }
}

export default App;
