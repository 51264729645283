import auth, { IAuthState } from "src/redux/modules/auth";
import info, { IInfoState } from "src/redux/modules/info";
import audio, { IAudioState } from "src/redux/modules/audio";
import wallets, { IWalletsState } from "src/redux/modules/wallets";
import affiliate, { IAffiliateState } from "src/redux/modules/affiliate";
import { Action } from "redux";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";

export default function createReducers(asyncReducers: any) {
    return {
        online: (v = true) => v,
        auth,
        info,
        audio,
        wallets,
        affiliate,
        ...asyncReducers,
    };
}

interface RootState {
    affiliate: IAffiliateState;
    audio: IAudioState;
    auth: IAuthState;
    info: IInfoState;
    wallets: IWalletsState;
}

// Typed version of useDispatch
export type AppDispatch = (action: Action) => void;
export const useAppDispatch: () => AppDispatch = useDispatch;

// Typed version of useSelector
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;