import { useEffect, useState } from "react";
import apiClient from "src/helpers/apiClient";

function useFetch<TResponse>(url: string, fetch?: () => void) {
    const [data, setData] = useState<TResponse | undefined>();
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [errorLoader, setErrorLoader] = useState(false);

    useEffect(() => {
        let isMounted = true;
        apiClient()
            .get(url)
            .then(res => {
                if (isMounted) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    setData(res);
                    setLoaded(() => true);
                }
            })
            .catch(e => {
                setError(() => e);
                setErrorLoader(() => true);
            });
        return () => {
            isMounted = false;
        };
    }, [url, fetch]);

    return {
        data,
        loaded,
        error,
        errorLoader,
    };
}

export default useFetch;
