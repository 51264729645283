import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./styles.scss";
import Loader from "src/components/loader3/Loader";
import { useAppSelector } from "src/redux/reducer";

interface IProtectedProps {
    children: ReactNode;
    requireLogin?: boolean;
}

const cx = classNames.bind(styles);

export default function Protected({ children = [], requireLogin = false }: IProtectedProps) {
    const isLoading = useAppSelector(state => !state.auth.loaded);
    const isLoggedIn = useAppSelector(state => !!state.auth.user);

    if (isLoading) {
        return (
            <div className={cx("loading-container")}>
                <Loader />
            </div>
        );
    }
    if (requireLogin && !isLoggedIn) {
        return <Redirect to="/" />;
    }
    return children;
}