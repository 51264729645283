import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Redirect, withRouter } from "react-router";
import { signUp } from "src/redux/modules/affiliate";
import Layout from "src/affiliate/components/Layout";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import RegistrationForm from "src/affiliate/components/registration/RegistrationForm";
import { registrationSchema } from "src/affiliate/components/registration/config";
import EmailVerificationAndExpiration from "src/affiliate/components/email-verification-and-expiration/EmailVerificationAndExpiration";
import Suspended from "src/affiliate/components/account-suspended/Suspended";
import Verification from "src/affiliate/components/email-verification/Verification";
import useDebounce from "src/affiliate/hooks/use-debounce";
import apiClient from "src/helpers/apiClient";
import { useAppDispatch, useAppSelector } from "src/redux/reducer";

interface IFormValues {
    name: string;
    email: string;
    sot: string;
}

const Registration = () => {
    const [checked, setChecked] = useState(false);
    const [serverError, setServerError] = useState("");
    const dispatch = useAppDispatch();
    const affiliateStatus = useAppSelector(state => state.affiliate.status);

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
        watch,
    } = useForm<IFormValues>({
        resolver: yupResolver(registrationSchema),
        mode: "all",
    });
    const watchEmail = watch("email");
    const debounceValue = useDebounce(watchEmail, 500);

    const handleOnChange = () => {
        setChecked(prev => !prev);
    };

    useEffect(() => {
        if (debounceValue) {
            setServerError(() => "error");
            apiClient()
                .get(`/api/v1/affiliate/account/email/duplicate?email=${debounceValue}`)
                .then(() => {
                    setServerError("");
                })
                .catch(() => {
                    setServerError("This email is already registered");
                });
        }
        setServerError("");
    }, [debounceValue]);

    const onSubmit = (registrationData: IFormValues) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        dispatch(signUp(registrationData)).catch(error => {
            if (error.status) {
                setServerError("This email is already registered");
            }
        });
    };
    let mainComponent;
    switch (affiliateStatus) {
        case "ACTIVE":
            mainComponent = <Redirect to="dashboard" />;
            break;
        case "PENDING":
            mainComponent = (
                <EmailVerificationAndExpiration
                    title="Verify Your Email"
                    description="Please check your email and click the link to activate your partner account"
                />
            );
            break;
        case "SUSPENDED":
            mainComponent = <Suspended />;
            break;
        case "REVIEWING":
            mainComponent = <Verification />;
            break;
        default:
            mainComponent = (
                <RegistrationForm
                    checked={checked}
                    onSubmit={onSubmit}
                    handleOnChange={handleOnChange}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    isValid={isValid}
                    register={register}
                    serverError={serverError}
                />
            );
    }

    return (
        <>
            <Helmet>
                <title>Registration</title>
                <meta property="og:title" content="Peergame registration" />
                <meta
                    name="description"
                    content="The original Bitcoin SV Dice Game. Provably fair with the lowest house edge, instant payout, and enhanced privacy"
                />
            </Helmet>
            <Layout transparent>
                <FlexContainer flexDirection="row" justifyContent="center" padding="60px 0 218px 0" mobilePadding="60px 0 200px 0">
                    {mainComponent}
                </FlexContainer>
            </Layout>
        </>
    );
};

export default withRouter(Registration);
