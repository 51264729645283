import { useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import Loader from "src/components/loader2/Loader";
import Img1st from "./assets/1st.png";
import Img2nd from "./assets/2nd.png";
import Img3rd from "./assets/3rd.png";
import IconArrowDown from "src/components/game/assets/images/arrow-down.svg?inline";
import ImgRaceTrophy from "./assets/race-trophy.png";
import UpdateIcon from "../../../containers/Races/assets/images/update-icon.svg?inline";
import SwapIcon from "../../../containers/Races/assets/images/swap.svg?inline";
import ImgCoins from "./assets/coins.svg";
import { useMobileOnly } from "src/helpers/responsive";
import CountdownTimer from "src/components/stats-table/Races/CountdownTimer";
import { axiosInstance } from "src/helpers/apiClient";
import { ILeaderboardRankingResponse } from "src/api/responses/leaderboard";

interface IRewardItem {
    reward: string;
    ranking: string;
}

interface ISettings {
    season: string;
    rewards: IRewardItem[];
    total_reward: string;
    end_time: string;
}

interface IRaceDetailsProps {
    isOpen: boolean;
    settings: ISettings;
    toggleOpen: () => void;
    raceType: string;
}

const cx = classNames.bind(styles);

const variableCols = [
    { label: "Points", key: "point" },
    { label: "Profit (BSV)", key: "profit" },
    { label: "Multiplier", key: "multiply" },
];

const rankingImages = { 1: Img1st, 2: Img2nd, 3: Img3rd };

async function getRace(season: string, round: string) {
    const { data: { data: { user_rank, ranking } } } =
        await axiosInstance.get<ILeaderboardRankingResponse>("/v1/leaderboard/ranking", {
            params: { season, round },
        })

    return { userRank: user_rank, ranking: ranking || [] }
}

const round = "curr";

export default function RaceDetails({
    isOpen, settings, toggleOpen, raceType,
}: IRaceDetailsProps) {
    const {
        season, rewards = [], total_reward: totalReward, end_time: endTime,
    } = settings;

    const buttonResetTimeout = useRef();
    const queryClient = useQueryClient();
    const isMobileOnly = useMobileOnly();
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [variableColIndex, setVariableColIndex] = useState(0);
    const { data, isLoading, isFetching } = useQuery(["race", raceType, round], () => getRace(season, round), {
        onSettled: () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            buttonResetTimeout.current = setTimeout(() => setButtonDisabled(false), 10 * 1000);
        },
        refetchOnWindowFocus: true,
        enabled: Object.keys(settings).length !== 0 && !!raceType,
    });

    useEffect(() => {
        if (isFetching) {
            clearTimeout(buttonResetTimeout.current);
            setButtonDisabled(true);
        }
    }, [isFetching]);

    let rowComponents = rewards.map((reward, index) => {
        // copy object to avoid modifying the original object
        const rowData = { ...data?.ranking[index] } || {};
        const formattedRowData = {
            ...rowData,
            multiply: rowData.multiply || rowData.multiply === 0 ? Math.round(rowData.multiply) : "-",
            profit: rowData.profit?.toFixed(4) || "-",
            point: rowData.point || rowData.point === 0 ? rowData.point : "-",
            paymail: rowData.paymail || "-",
        }

        let variableColCells;
        if (isMobileOnly) {
            variableColCells = <td>{formattedRowData[variableCols[variableColIndex].key]}</td>;
        } else {
            variableColCells = variableCols
                .slice(0)
                .reverse()
                .map(({ key }) => <td key={key}>{formattedRowData[key]}</td>);
        }

        return (
            <tr key={reward.ranking}>
                <td>
                    <div className="flex items-center">
                        <span>{reward.ranking}</span>
                        {rankingImages[reward.ranking] && <img src={rankingImages[reward.ranking]} alt="ranking icon" />}
                    </div>
                </td>
                <td className="flex items-center">
                    <div className="flex items-center">
                        {formattedRowData.profile_image ? <img src={formattedRowData.profile_image} alt="user icon" /> : null}
                        <span>{formattedRowData.paymail}</span>
                    </div>
                </td>
                {variableColCells}
                <td className="flex items-center justify-end">
                    <span>{reward.reward}</span>
                    <img src={ImgCoins} alt="coins" />
                </td>
            </tr>
        );
    });

    if (isLoading) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        rowComponents = (
            <tr className={cx("loader")}>
                <td colSpan={6}>
                    <Loader />
                </td>
            </tr>
        );
    }

    let variableColHeaders;
    if (isMobileOnly) {
        variableColHeaders = (
            <th className={cx("icon-column")}>
                {variableCols[variableColIndex].label}
                <button
                    type="button"
                    onClick={() => setVariableColIndex(variableColIndex === variableCols.length - 1 ? 0 : variableColIndex + 1)}
                >
                    <SwapIcon />
                </button>
            </th>
        );
    } else {
        variableColHeaders = variableCols
            .slice(0)
            .reverse()
            .map(({ label }) => <th key={label}>{label}</th>);
    }

    function updateRace() {
        if (!isOpen) {
            queryClient.invalidateQueries(["race", raceType, round]);
            toggleOpen();
        } else {
            queryClient.invalidateQueries(["race", raceType, round]);
        }
    }

    return (
        <>
            <div className={cx("top-section")}>
                <div className={cx("race-title")}>
                    <img src={ImgRaceTrophy} alt="race-trophy" />
                    <span className={cx("small bold")}>{raceType === "weekly" ? "Weekly Race" : "Daily Race"}</span>
                    <div className={cx("info-squares")}>
                        <h6>{totalReward} BSV</h6>
                    </div>
                </div>
                {isMobileOnly && !isOpen ? null : (
                    <div className={cx("race-title-update")}>
                        <CountdownTimer endsAt={endTime} raceType={raceType} />
                        <button type="button" className={cx("update-button")} disabled={buttonDisabled} onClick={updateRace}>
                            Update
                            <UpdateIcon />
                        </button>
                    </div>
                )}

                <div className={cx("race-btn")}>
                    <button type="button" onClick={toggleOpen}>
                        <IconArrowDown className={cx({ rotate: isOpen })} />
                    </button>
                </div>
            </div>

            {isOpen && (
                <>
                    <div className={cx("my-stats")}>
                        <div>
                            <span>Your Rank</span>
                            <span>{data?.userRank ? data.userRank?.ranking : "-"}</span>
                        </div>
                        <div>
                            <span>Your Points</span>
                            <span>{data?.userRank ? data.userRank?.point : "-"}</span>
                        </div>
                    </div>
                    <div className={cx("ranking-table")}>
                        <table>
                            <thead>
                            <tr>
                                <th>Place</th>
                                <th>Player</th>
                                {variableColHeaders}
                                <th>Prize (BSV)</th>
                            </tr>
                            </thead>
                            <tbody>{rowComponents}</tbody>
                        </table>
                    </div>
                </>
            )}
        </>
    );
}

