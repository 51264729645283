/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import "core-js/stable";
import "regenerator-runtime/runtime";
import "lazysizes";

import "src/core/analytics/google-tag-manager";
import React from "react";
import { Router, Route } from "react-router";
import { QueryParamProvider } from "use-query-params";
import { renderRoutes } from "react-router-config";
import { trigger } from "redial";
import { createBrowserHistory } from "history";
import { createRoot, hydrateRoot } from "react-dom/client";
import { getStoredState } from "redux-persist";
import { CookiesProvider } from "react-cookie";
import localForage from "localforage";
import createStore from "src/redux/create";
import apiClient from "src/helpers/apiClient";
import routes from "src/routes";
import isOnline from "src/utils/isOnline";
import asyncMatchRoutes from "src/utils/asyncMatchRoutes";
import Provider from "components/Provider/Provider";
import NProgress from "nprogress";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";

import * as serviceWorker from "./registerServiceWorker";
import Crash from "containers/Crash/Crash";
import RouterTrigger from "src/components/RouterTrigger/router-trigger";

const persistConfig = {
    key: "hello", storage: localForage, stateReconciler(inboundState, originalState) {
        // Ignore state from cookies, only use preloadedState from window object
        return originalState;
    }, whitelist: [],
};
Sentry.init({ dsn: process.env.SENTRY_DSN, environment: process.env.NODE_ENV });

const dest = document.getElementById("react-root");

const client = apiClient();
const providers = {
    client,
};

(async () => {
    const preloadedState = await getStoredState(persistConfig);
    const online = window.__data ? true : await isOnline();

    const history = createBrowserHistory();
    const store = createStore({
        history, data: {
            ...preloadedState, ...window.__data, online,
        }, helpers: providers, persistConfig,
    });

    const triggerHooks = async (_routes, pathname) => {
        NProgress.start();

        const { components, match, params } = await asyncMatchRoutes(_routes, pathname);
        const triggerLocals = {
            ...providers, store, match, params, history, location: history.location,
        };

        await trigger("inject", components, triggerLocals);

        // Don't fetch data for initial route, server has already done the work:
        if (window.__PRELOADED__) {
            // Delete initial data so that subsequent data fetches can occur:
            delete window.__PRELOADED__;
        } else {
            // Fetch mandatory data dependencies for 2nd route change onwards:
            await trigger("fetch", components, triggerLocals);
        }
        await trigger("defer", components, triggerLocals);

        NProgress.done();
    };

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: { refetchOnWindowFocus: false, refetchOnReconnect: false },
        },
    });

    const hydrate = _routes => {
        const element = (
            <Sentry.ErrorBoundary fallback={<Crash />}>
                <Provider store={store} {...providers}>
                    <QueryClientProvider client={queryClient}>
                        <ReactQueryDevtools />
                        <Router history={history}>
                            <QueryParamProvider ReactRouterRoute={Route}>
                                <RouterTrigger trigger={pathname => triggerHooks(_routes, pathname)}>
                                    <CookiesProvider>{renderRoutes(_routes)}</CookiesProvider>
                                </RouterTrigger>
                            </QueryParamProvider>
                        </Router>
                    </QueryClientProvider>
                </Provider>
            </Sentry.ErrorBoundary>
        );
        if (window.__PRERENDER_INJECTED && !window.__PRERENDER_INJECTED.injected) {
            hydrateRoot(dest, element);
        } else {
            const root = createRoot(dest);
            root.render(element);
        }
    };

    hydrate(routes);

    // Hot reload
    if (module.hot) {
        module.hot.accept("./routes", () => {
            const nextRoutes = require("src/routes");
            hydrate(nextRoutes.__esModule ? nextRoutes.default : nextRoutes);
        });
    }

    // Server-side rendering check
    if (process.env.NODE_ENV !== "production") {
        window.React = React; // enable debugger
    }
})();

// Service worker
serviceWorker.register({
    scope: "/",
});