import axios, { AxiosInstance as AxiosInstanceType, AxiosResponse } from "axios";
import appConfig from "src/config";
import AxiosInstance from "@pg/api-client";

export const axiosInstance = new AxiosInstance(appConfig.apiHost);

// TODO Remove it when apiClient wont be used anymore
let instance: AxiosInstanceType | undefined;

export default function apiClient(): AxiosInstanceType {
    if (!instance) {
        instance = axios.create({
            baseURL: appConfig.apiHost,
            timeout: 1000 * 20,
            withCredentials: true,
        })

        instance.interceptors.response.use(
            (response: AxiosResponse) => response.data,
            error => Promise.reject(error.response ? { status: error.response.status, ...error.response.data } : error)
        );
    }
    return instance;
}