import { useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { withCookies, Cookies } from "react-cookie";
import classNames from "classnames/bind";
import ageCheck from "assets/img/age-check.png";
import { COOKIE } from "src/utils/defines";
import { iAmAdult } from "src/redux/modules/info";
import { useAppDispatch } from "src/redux/reducer";

const styles = isMobileOnly ? require("./assets/mobile.scss").default : require("./assets/desktop.scss").default;

const cx = classNames.bind(styles);

interface IAreYouAdultProps {
    cookies: Cookies;
}

const AreYouAdult = ({ cookies }: IAreYouAdultProps) => {
    const [on, setOn] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if ((window as any).__PRERENDER_INJECTED && (window as any).__PRERENDER_INJECTED.injected) {
            return;
        }

        const adult = cookies.get(COOKIE._ARE_YOU_ADULT) === "true";
        if (adult) {
            dispatch(iAmAdult());
        }
        setOn(!adult);
    }, [cookies, dispatch]);

    const handleClickYes = () => {
        setOn(false);
        cookies.set(COOKIE._ARE_YOU_ADULT, "true", { path: "/", maxAge: 31536000 });
        dispatch(iAmAdult());
    };

    const handleClickNo = () => {
        setOn(false);
    };

    return (
        <div className={cx("are-you-adult", { on })}>
            <div className={cx("are-you-adult-popup")}>
                <img src={ageCheck} alt="" />
                {!isMobileOnly ? <h2>Are you 18 or older?</h2> : <h3>Are you 18 or older?</h3>}
                <button type="button" className={cx("button", "no", "bold")} onClick={handleClickNo}>
                    No
                </button>
                <button type="button" className={cx("button", "yes", "bold")} onClick={handleClickYes}>
                    Yes
                </button>
            </div>
        </div>
    );
};

export default withCookies(AreYouAdult);
