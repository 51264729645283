import {useCookies} from "react-cookie";

export default function useAffiliateScript() {
    const [cookies] = useCookies(["pg.campaign"]);
    const campaignCode = cookies["pg.campaign"];

    return campaignCode
        ? `|COMBINE|peergame.com|REFERRAL|${JSON.stringify({
            v: "0",
            d: {
                c: campaignCode,
            },
        })}`
        : "";
}
useAffiliateScript.propTypes = {};
useAffiliateScript.defaultProps = {};
