import { useState, useRef } from "react";
import classNames from "classnames/bind";
import { Link, useLocation } from "react-router-dom";
import { logout } from "src/redux/modules/auth";
import { useQuery } from "react-query";
import styles from "./assets/styles.scss";
import IconReferrals from "./assets/images/referrals.svg?inline";
import IconLogout from "./assets/images/logout.svg?inline";
import ImgTrophy from "./assets/images/races-trophy.png";
import { basePath, routes } from "src/containers/Account/accountRoutes";
import { axiosInstance } from "src/helpers/apiClient";
import useClickOutside from "src/helpers/useClickOutside";
import usePageScroll from "src/helpers/usePageScroll";
import useLocationChange from "src/helpers/useLocationChange";
import walletProviders from "src/config/walletProviders";
import { useAppDispatch, useAppSelector } from "src/redux/reducer";
import { IPointsResponse } from "src/api/responses/points";

interface IMyAccountProps {
    reverse?: boolean;
}

const cx = classNames.bind(styles);

async function getMyPoints() {
    const { data: { data } } = await axiosInstance.get<IPointsResponse>("v1/user/points");

    return data
}

export default function MyAccount({ reverse = false }: IMyAccountProps) {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const popupRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const walletName = useAppSelector(state => state.wallets.currentWallet?.name);

    const { nickname, profile_image: profilePic } = useAppSelector(state => state.auth.user) || {};
    const [isOpen, setIsOpen] = useState(false);
    const { data: myPoints } = useQuery("mypoints", getMyPoints);

    useClickOutside(() => {
        setIsOpen(false);
    }, [popupRef, buttonRef]);
    usePageScroll(() => setIsOpen(false));
    useLocationChange(() => setIsOpen(false));

    return (
        <>
            <button
                type="button"
                ref={buttonRef}
                className={cx("account-button", { active: isOpen })}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>{nickname}</span>
                <div className={cx("avatar-container")}>
                    <img className={cx("avatar")} src={profilePic} alt="avatar" />
                    <div className={cx("wallet-icon")}>
                        <img src={walletProviders[walletName]?.icon} alt="" />
                    </div>
                </div>
            </button>
            <div className={cx("account-popup", { open: isOpen, reverse })} ref={popupRef}>
                <div className={cx("ranking", { reverse })}>
          <span className={cx("heading", "bold", "small")}>
            <img src={ImgTrophy} alt="trophy" />
            My Daily Race
          </span>
                    <div>
                        <div>
                            <span className={cx("small")}>Rank</span>
                            <span className={cx("small")}>{myPoints?.daily_rank?.ranking || "-"}</span>
                        </div>
                        <div>
                            <span className={cx("small")}>Points</span>
                            <span className={cx("small")}>{myPoints?.daily_rank ? myPoints?.daily_rank.point : "-"}</span>
                        </div>
                    </div>
                </div>
                <div className={cx("navigation")}>
                    {routes.map(({ path, label, icon }) => (
                        <Link
                            key={label}
                            className={cx({ active: pathname === `${basePath}${path}`, reverse })}
                            to={`${basePath}${path}`}
                        >
                            {icon}
                            {label}
                        </Link>
                    ))}
                    <Link to="/partners" className={cx({ reverse })}>
                        <IconReferrals />
                        Referrals
                    </Link>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-expect-error */}
                    <button type="button" className={cx({ reverse })} onClick={() => dispatch(logout())}>
                        <IconLogout />
                        Sign out
                    </button>
                </div>
            </div>
        </>
    );
}