import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import styles from "./assets/styles.scss";
import GameThumbnail from "src/components/game-thumbnail/GameThumbnail";
import { useDesktopLargeOnly, useDesktopOnly } from "src/helpers/responsive";
import useGamesList from "src/games/hooks/useGamesList";
import BannerGrid from "src/containers/Landing/BannerGrid/BannerGrid";
import Leaderboard from "src/containers/Landing/Leaderboard";
import Testimonials from "src/containers/Landing/Testimonials";
import PageContainer from "src/containers/PageContainer/PageContainer";
import IconLeaderboard from "./assets/images/leaderboard-icon.svg?inline";

import IconGames from "../../assets/img/games.svg?inline";
import IconSlots from "../../assets/img/slots.svg?inline";
import IconTableGames from "../../assets/img/table-games.svg?inline";

const cx = classNames.bind(styles);

const gameSections = [
    {
        title: "IN-HOUSE GAMES",
        icon: <IconGames />,
        category: "inhouse",
        viewAll: false,
        limitSize: () => 100,
    },
    {
        title: "SLOTS",
        icon: <IconSlots />,
        category: "slots",
        viewAll: true,
        limitSize: (isDesktopOnly: boolean) => (isDesktopOnly ? 10 : 9),
    },
    {
        title: "TABLE GAMES",
        icon: <IconTableGames />,
        category: "table",
        viewAll: true,
        limitSize: (isDesktopOnly: boolean) => (isDesktopOnly ? 10 : 9),
    },
];

export default function Landing() {
    const isDesktopLargeOnly = useDesktopLargeOnly();
    const isDesktopOnly = useDesktopOnly();
    const { games } = useGamesList("", true);

    function getContent() {
        const gamesContent = (
            <>
                {gameSections.map(({
                    title: sectionTitle, icon, category, viewAll, limitSize,
                }) => (
                    <div key={sectionTitle} className="mb-16">
                        <div className={cx("icon-heading")}>
                            {icon}
                            <h3>{sectionTitle}</h3>
                            {viewAll && <Link to={`/games?category=${category}`}>View All</Link>}
                        </div>
                        <div className={cx("games-grid")}>
                            {games
                                .filter(game => game.category === category)
                                .slice(0, limitSize(isDesktopOnly))
                                .map(({
                                    title, thumbnailSrc, url, provider,
                                }) => {
                                    if (title === "Blackjack") {
                                        console.log("Game", title, "thumbnail", thumbnailSrc);
                                    }
                                    return (
                                        <GameThumbnail
                                            key={title}
                                            url={url}
                                            provider={provider}
                                            thumbnailSrc={thumbnailSrc}
                                            title={title}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                ))}
            </>
        );

        if (isDesktopLargeOnly) {
            return (
                <>
                    <div className={cx("desktop-top-section")}>
                        <BannerGrid />
                        <Leaderboard />
                    </div>
                    {gamesContent}
                    <Testimonials />
                </>
            );
        }
        return (
            <>
                <BannerGrid />
                {gamesContent}
                <div>
                    <div className={cx("icon-heading")}>
                        <IconLeaderboard />
                        <h3>LEADERBOARD</h3>
                    </div>
                    <Leaderboard />
                </div>
                <Testimonials />
            </>
        );
    }

    return (
        <PageContainer showHeader showFooter dark contentClass={cx("landing-page")} pageTitle="Peergame">
            {getContent()}
        </PageContainer>
    );
}
