import { useRef, useState, useEffect } from "react";
import classNames from "classnames/bind";
import { useSelector } from "react-redux";
import { useMobileOnly } from "helpers/responsive";
import BottomDrawer from "src/components/bottom-drawer/BottomDrawer";
import Modal from "src/components/modal/Modal";
import useClickOutside from "helpers/useClickOutside";
import styles from "./styles.scss";
import { assetIcons } from "config";
import useTableDeposit from "../GameBalance/useTableDeposit";
import Button from "src/components/button/Button";
import ConnectWallet from "src/components/connect-wallet/ConnectWallet";
import useCashout from "./useCashout";

import IconClose from "src/components/modal/assets/close.svg?inline";
import IconLogout from "../../assets/images/logout.svg?inline";

const cx = classNames.bind(styles);

export default function Cashout() {
    const isMobileOnly = useMobileOnly();
    const drawerRef = useRef(null);
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const { depositBalance } = useTableDeposit();
    const {
        pendingCashout,
        holdingCashout,
        cashout,
        isCashingOut,
        cancelHolding,
        isCancellingHolding,
        refetchHolding,
    } = useCashout();
    const isLoggedIn = useSelector(state => !!state.auth.user);
    const [showConnectWallet, setShowConnectWallet] = useState(false);
    const connectWalletRef = useRef();

    useEffect(() => {
        if (isOpen) {
            refetchHolding();
        }
    }, [isOpen]);

    function onCashout() {
        cashout().then(() => {
            setIsOpen(false);
        });
    }

    function onCancelCashout() {
        cancelHolding().then(() => {
            setIsOpen(false);
        });
    }

    useClickOutside(() => {
        setIsOpen(false);
    }, [drawerRef, buttonRef]);

    function getButtons() {
        const cancelButton = (
            <Button className={cx("bold")} onClick={() => setIsOpen(false)} isDisabled={isCashingOut || isCancellingHolding}>
                Cancel
            </Button>
        );
        if (isLoggedIn) {
            if (!holdingCashout?.isZero()) {
                return (
                    <Button className={cx("bold", "cancel-cashout")} isLoading={isCancellingHolding} onClick={onCancelCashout}>
                        Cancel Pending Cashout
                    </Button>
                );
            }
            return (
                <>
                    {cancelButton}
                    <Button
                        className={cx("bold", "cashout")}
                        isLoading={isCashingOut}
                        onClick={onCashout}
                        isDisabled={depositBalance?.isZero()}
                    >
                        Cashout
                    </Button>
                </>
            );
        }
        return (
            <>
                {cancelButton}
                <button
                    type="button"
                    className={cx("bold", "connect-wallet")}
                    ref={connectWalletRef}
                    onClick={() => {
                        setShowConnectWallet(true);
                        setIsOpen(false);
                    }}
                >
                    Connect Wallet
                </button>
                <ConnectWallet
                    isOpen={showConnectWallet}
                    onClose={() => setShowConnectWallet(false)}
                    buttonRefs={[connectWalletRef]}
                />
            </>
        );
    }

    const content = (
        <div className={cx("content")}>
            <p>Your game balance will be sent directly to your wallet.</p>
            <span className={cx("xsmall")}>GAME BALANCE</span>
            <div className={cx("balance")}>
                {assetIcons.BSV({})}
                <span className={cx("bold", "large")}>{depositBalance?.toNumber() || "0.0000"}</span>
            </div>
            {pendingCashout && !pendingCashout.isZero() && (
                <div className={cx("pending-cashout")}>
                    <span className={cx("label", "xsmall")}>PENDING CASHOUT</span>
                    {assetIcons.BSV({})}
                    <span className={cx("amount", "bold")}>{pendingCashout.toString()}</span>
                </div>
            )}
            <div className={cx("buttons")}>{getButtons()}</div>
        </div>
    );

    return (
        <>
            <button
                type="button"
                className={cx("cashout-button", "small")}
                onClick={() => setIsOpen(!isOpen)}
                ref={buttonRef}
            >
                <IconLogout />
                Cashout
            </button>
            {isMobileOnly ? (
                <BottomDrawer isOpen={isOpen} className={cx("mobile-drawer")} ref={drawerRef}>
                    <div className={cx("header", "cashout-header")}>
                        <h4>Cashout</h4>
                        <button type="button" onClick={() => setIsOpen(false)}>
                            <IconClose />
                        </button>
                    </div>
                    {content}
                </BottomDrawer>
            ) : (
                <Modal
                    isOpen={isOpen}
                    onClose={() => !isCashingOut && setIsOpen(false)}
                    header="Cashout"
                    modalClassName={cx("modal")}
                >
                    {content}
                </Modal>
            )}
        </>
    );
}
