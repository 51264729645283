import { useContext, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import BigNumber from "bignumber.js";
import { useSelector } from "react-redux";
import { axiosInstance } from "helpers/apiClient";
import useAppStore from "useAppStore";
import GameCodeContext from "containers/Games/GameCodeContext";

async function getPending() {
    const { data } = await axiosInstance.get("/v1/deposit/history", {
        params: {
            asset: "BSV",
            task: "DEPOSIT",
            status: "PENDING",
        },
    });
    return data.data.reduce((accumulator, { amount }) => accumulator.plus(amount), new BigNumber(0));
}

async function getBalance() {
    const { data } = await axiosInstance.get("/v1/deposit/balance", { params: { asset: "BSV" } });
    return new BigNumber(data.data);
}

async function getSettings() {
    const { data } = await axiosInstance.get("/v1/deposit/setting", { params: { asset: "BSV" } });
    return data.data;
}

export default function useTableDeposit() {
    const queryClient = useQueryClient();
    const gameCode = useContext(GameCodeContext);
    const isLoggedIn = useSelector(state => !!state.auth.user);
    const { data: depositBalance, isLoading } = useQuery("depositBalance", getBalance, {
        enabled: isLoggedIn,
    });
    const { data: depositSettings, isLoading: isLoadingSettings } = useQuery("depositSettings", getSettings, {
        enabled: isLoggedIn,
    });
    const { data: pendingAmount, isLoading: isLoadingPending } = useQuery("pendingDeposit", getPending, {
        enabled: isLoggedIn,
    });

    function setPendingBalance() {
        queryClient.setQueryData("pendingDeposit", new BigNumber(1));
    }

    function setBalance(newBalance) {
        queryClient.setQueryData("depositBalance", new BigNumber(newBalance));
    }

    useEffect(() => {
        const { socket } = useAppStore.getState();

        if (!socket.hasListeners("REWARD")) {
            socket.on("REWARD", data => {
                const { amount } = JSON.parse(data);
                queryClient.setQueryData("depositBalance", balance => balance.plus(amount));
            });
        }
        if (!socket.hasListeners("DEPOSIT_COMPLETED")) {
            socket.on("DEPOSIT_COMPLETED", data => {
                const { amount } = JSON.parse(data);
                queryClient.setQueryData("depositBalance", balance => balance.plus(amount));
                queryClient.setQueryData("pendingDeposit", new BigNumber(0));
                useAppStore.getState().setAssetBalance(currentBalance => ({
                    BSV: currentBalance.BSV.minus(amount),
                }));
            });
        }
    }, []);

    useEffect(() => {
        const { socket } = useAppStore.getState();

        if (gameCode === "slots" && !socket.hasListeners("PARTICIPATE")) {
            socket.on("PARTICIPATE", data => {
                const { value } = JSON.parse(data);
                queryClient.setQueryData("depositBalance", balance => balance.minus(value.amount));
            });
            return () => socket.off("PARTICIPATE");
        }
    }, [gameCode]);

    return {
        depositBalance,
        isLoading: isLoading || isLoadingPending || isLoadingSettings,
        setBalance,
        hasPending: !pendingAmount?.isZero(),
        setPendingBalance,
        settings: depositSettings,
    };
}
