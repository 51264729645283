import useAppStore from "src/useAppStore";
import config from "src/config";

export interface IAuthState {
    loaded: boolean;
    user: IUser | null;
    moneyButtonUserIdHash: string | null;
}

export interface IUser {
    nickname?: string;
    profile_image?: string;
    [key: string]: any; // To allow any additional properties in the user object
}

// Action types
export const LOAD = "game/auth/LOAD";
export const LOAD_SUCCESS = "game/auth/LOAD_SUCCESS";
export const LOAD_FAIL = "game/auth/LOAD_FAIL";
export const LOGOUT = "game/auth/LOGOUT";
export const SET_MONEY_BUTTON_USER_ID_HASH = "game/auth/SET_MONEY_BUTTON_USER_ID_HASH";
export const SET_NICKNAME = "set/nickname";
export const SET_AVATAR = "set/avatar";
export const LOGOUT_SUCCESS = "game/auth/LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "game/auth/LOGOUT_FAIL";

// Action interfaces
interface ILoadSuccessAction {
    type: typeof LOAD_SUCCESS;
    result: IUser;
}

interface ILoadFailAction {
    type: typeof LOAD_FAIL;
}

interface ILogoutAction {
    type: typeof LOGOUT;
}

interface ISetNicknameAction {
    type: typeof SET_NICKNAME;
    nickname: string;
}

interface ISetAvatarAction {
    type: typeof SET_AVATAR;
    newUrl: string;
}

interface ISetMoneyButtonUserIdHashAction {
    type: typeof SET_MONEY_BUTTON_USER_ID_HASH;
    moneyButtonUserIdHash: string;
}

type TAuthActionTypes =
    | ILoadSuccessAction
    | ILoadFailAction
    | ILogoutAction
    | ISetNicknameAction
    | ISetAvatarAction
    | ISetMoneyButtonUserIdHashAction;

const initialState: IAuthState = {
    loaded: false,
    user: null,
    moneyButtonUserIdHash: null,
};

export default function auth(
    state = initialState,
    action: TAuthActionTypes
): IAuthState {
    switch (action.type) {
        case LOAD_SUCCESS:
            useAppStore.getState().setUserData(action.result);
            return {
                ...state,
                loaded: true,
                user: action.result,
            };
        case LOAD_FAIL:
            return {
                ...state,
                loaded: true,
            };
        case LOGOUT:
            useAppStore.getState().setSelectedAsset("BSV");
            return { ...initialState, loaded: true };
        case SET_NICKNAME:
            return { ...state, user: { ...state.user, nickname: action.nickname } };
        case SET_AVATAR:
            return {
                ...state,
                user: { ...state.user, profile_image: action.newUrl },
            };
        case SET_MONEY_BUTTON_USER_ID_HASH:
            return {
                ...state,
                moneyButtonUserIdHash: action.moneyButtonUserIdHash,
            };
        default:
            return state;
    }
}

// Define the return types for each action
interface Client {
    get: (url: string) => Promise<any>;
    post: (url: string, data?: any) => Promise<any>;
}

export function load() {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: async ({ client }: { client: Client }) => {
            const { data } = await client.get("/auth/v2/me");
            return data;
        },
    };
}

export function setNickname(nickname: string) {
    return {
        type: SET_NICKNAME,
        nickname,
    };
}

export function setAvatar(newUrl: string) {
    return {
        type: SET_AVATAR,
        newUrl,
    };
}

export function setMoneyButtonUserIdHash(moneyButtonUserIdHash: string) {
    return {
        type: SET_MONEY_BUTTON_USER_ID_HASH,
        moneyButtonUserIdHash,
    };
}

export function getLoginUrl(provider: string): string {
    return `${config.apiHost}/auth/v2/${provider}/login?redirectUrl=${document.URL}`;
}

export function logout() {
    return {
        types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
        promise: async ({ client }: { client: Client }) => client.post("/auth/v2/logout"),
    };
}