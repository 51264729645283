import { ReactElement } from "react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import MyAccount from "src/components/header/MyAccount";
import SideNav from "src/components/header/SideNav";
import MyPoints from "src/components/header/MyPoints/MyPoints";

import PeergameLogoLight from "./assets/images/peergame-logo-light.svg?inline";
import PeergameLogoDark from "./assets/images/peergame-logo-dark.svg?inline";
import IconRaces from "./assets/images/races.svg?inline";
import IconSlots from "../../assets/img/slots.svg?inline";
import IconTableGames from "../../assets/img/table-games.svg?inline";
import IconGames from "../../assets/img/games.svg?inline";

import styles from "./assets/styles.scss";
import GamesHeader from "src/components/GamesHeader/GamesHeader";
import ConnectWalletButton from "src/components/connect-wallet/ConnectWalletButton";
import { useMobileOnly } from "src/helpers/responsive";
import useLocationChange from "src/helpers/useLocationChange";

interface IHeaderProps {
    game?: string | null;
    displaySubheader?: boolean;
    classes?: string[];
}

interface INavItem {
    route: string;
    classes: string;
    icon: ReactElement;
    text: string;
}

const cx = classNames.bind(styles);

export default function Header({ game = null, displaySubheader = true, classes = [] }: IHeaderProps) {
    const isMobileOnly = useMobileOnly();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const isLoggedIn = useSelector(state => !!state.auth.user);

    useLocationChange(() => {
        document.body.scrollTo({ top: 0, behavior: "smooth" });
    });

    function gamesHeader() {
        return displaySubheader && game ? <GamesHeader /> : null;
    }

    const navItems: INavItem[] = [
        {
            route: "/games",
            classes: cx("header-button", { reverse: !classes.includes("transparent") }),
            icon: <IconGames />,
            text: !isMobileOnly ? "Games" : "",
        },
        {
            route: "/games/slots",
            classes: cx("hide-mobile", "header-button", { reverse: !classes.includes("transparent") }),
            icon: <IconSlots />,
            text: "Slots",
        },
        {
            route: "/games/table",
            classes: cx("hide-mobile", "header-button", { reverse: !classes.includes("transparent") }),
            icon: <IconTableGames />,
            text: "Table Games",
        },
        {
            route: "/races",
            classes: cx("hide-mobile", "header-button", { reverse: !classes.includes("transparent") }),
            icon: <IconRaces />,
            text: "Races",
        },
    ];

    return (
        <>
            <header className={cx(classes)}>
                <div className={cx("left")}>
                    <SideNav reverse={!classes.includes("transparent")} />
                    <Link to="/" className={cx("logo")}>
                        {!classes.includes("transparent") && <PeergameLogoLight />}
                        {classes.includes("transparent") && <PeergameLogoDark />}
                    </Link>
                </div>

                <div className={cx("center")}>
                    {navItems.map(({ classes, icon, text, route }) => (
                        <Link key={text} to={route} className={classes}>
                            {icon}
                            {text}
                        </Link>
                    ))}

                </div>
                <div className={cx("right")}>
                    {isLoggedIn && <MyPoints reverse={!classes.includes("transparent")} />}
                    {isLoggedIn ? <MyAccount reverse={!classes.includes("transparent")} /> : <ConnectWalletButton />}
                </div>
            </header>
            {gamesHeader()}
        </>
    );
}