import { connect, ConnectedProps } from "react-redux";
import { Redirect } from "react-router";
import styled from "styled-components";
import Helmet from "react-helmet";
import LoginContent from "src/affiliate/components/login/LoginContent";
import Loader from "src/components/loader/Loader";
import Layout from "src/affiliate/components/Layout";

const FlexContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const mapStateToProps = ({ affiliate, auth }) => ({
    loggedIn: !!auth.user,
    isLoading: !auth.loaded || (!!auth.user && !affiliate.loaded),
    affiliateStatus: affiliate.status,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type TLoginProps = PropsFromRedux;

const Login = ({ loggedIn, isLoading, affiliateStatus }: TLoginProps) => {

    let mainComponent = <LoginContent />;
    if (isLoading) {
        mainComponent = <Loader />;
    } else if (affiliateStatus === "ACTIVE") {
        mainComponent = <Redirect to="dashboard" />;
    } else if (loggedIn) {
        mainComponent = <Redirect to="registration" />;
    }

    return (
        <>
            <Helmet>
                <title>Login</title>
                <meta property="og:title" content="Peergame login" />
                <meta
                    name="description"
                    content="The original Bitcoin SV Dice Game. Provably fair with the lowest house edge, instant payout,
                and enhanced privacy"
                />
            </Helmet>
            <div id="loginPage">
                <Layout transparent allowedRoutes>
                    <FlexContainer>{mainComponent}</FlexContainer>
                </Layout>
            </div>
        </>
    );
};

export default connector(Login);
