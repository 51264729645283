import classNames from "classnames/bind";
import { useInfiniteQuery } from "react-query";
import styles from "./assets/styles.scss";
import { axiosInstance } from "src/helpers/apiClient";
import HistoryTableRow from "src/containers/Account/History/HistoryTableRow";
import TableWrapper from "src/components/table-wrapper/TableWrapper";
import config from "src/config";
import { IGameHistoryResponse } from "src/api/responses/game-history";

const cx = classNames.bind(styles);

async function getHistory(gameCode: string, betStatus: string, { pageParam = null }: { pageParam?: string | null }) {
    const { data: { data } } = await axiosInstance.get<IGameHistoryResponse>("/v1/mypage/gamehistory", {
        params: {
            asset: config.asset,
            count: 30,
            [gameCode === "cfBattle" || gameCode === "blackjack" ? "last_id" : "last_game_id"]: pageParam,
            game_code: gameCode,
            status: betStatus,
        },
    });
    return data;
}

interface IHistoryTableProps {
    gameCode: string;
    betStatus: string;
}

const HistoryTable = ({ gameCode, betStatus }: IHistoryTableProps) => {
    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetching,
        isLoading,
    } = useInfiniteQuery(
        ["myhistory", gameCode, betStatus],
        context => getHistory(gameCode, betStatus, context),
        {
            getNextPageParam: lastGroup => {
                const lastElement = lastGroup[lastGroup.length - 1];
                if (gameCode === "cfBattle" || gameCode === "blackjack") {
                    return lastElement && lastElement.id;
                }
                return lastElement && lastElement.game_id;
            },
        },
    );

    const flatData = data.pages.flat();

    let columns: { label: string; className?: string; hide?: boolean; hideMobile?: boolean }[] = [
        { label: "Round ID" },
        { label: "Bet", className: cx("bet-amount") },
        { label: "Multiplier", hide: gameCode === "bitto" },
        { label: "My Pick", hideMobile: true },
        { label: "Result", hideMobile: true },
        { label: "Payout (BSV)" },
    ];

    if (["slots", "table", "casual"].includes(gameCode)) {
        columns = [
            { label: "Round ID", hideMobile: true },
            { label: "Game" },
            { label: "Bet", className: cx("bet-amount") },
            { label: "Multiplier", hide: gameCode === "bitto", hideMobile: true },
            { label: "Payout (BSV)" },
        ];
    }

    return (
        <TableWrapper
            isLoading={isLoading}
            columns={columns}
            className={cx("history-table")}
            infiniteScrollProps={{
                loadMore: () => fetchNextPage(),
                hasMore: !isFetching && hasNextPage,
            }}
            emptyPlaceholder={!flatData.length ? "You do not have any history" : ""}
        >
            {flatData.map(
                ({
                    game_id: gameId,
                    id,
                    asset,
                    balance: betAmount,
                    winning_rate: payoutRate,
                    game_result: result,
                    area,
                    winner_player: isWin,
                    status,
                    payout_balance: payout,
                    txid: txId,
                    payout_txid: payoutTxId,
                    txid_for_url: txIdForUrl,
                    game_name: gameName,
                }) => (
                    <HistoryTableRow
                        key={`${area}-${txId}-${id}`}
                        gameCode={gameCode}
                        gameId={gameId}
                        betAmount={betAmount}
                        payoutRate={payoutRate}
                        result={result}
                        area={area}
                        status={status}
                        isWin={isWin}
                        payout={payout}
                        txId={txId}
                        payoutTxId={payoutTxId}
                        asset={asset}
                        txIdForUrl={txIdForUrl}
                        gameName={gameName}
                    />
                ),
            )}
        </TableWrapper>
    );
};

export default HistoryTable;
