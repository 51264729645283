import { H3, Caption } from "src/affiliate/styled/typography.styled";
import { getLoginUrl } from "src/redux/modules/auth";
import walletProviders from "../../../config/walletProviders";
import { StyledAnchor, StyledCaption } from "src/affiliate/components/login/assets/styles/login-content.styled";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import LoginMobileList from "src/affiliate/components/login/LoginMobileList";

const LoginContent = () => (
    <>
        <FlexContainer mobileFlexDirection="column">
            <FlexContainer
                flexDirection="column"
                alignItems="center"
                padding="120px 0 480px 0"
                mobilePadding="60px 0 120px 0"
            >
                <H3 margin="0 0 40px 0"> Sign in to Peergame </H3>
                <StyledAnchor href={getLoginUrl("handcash")}>
                    <img style={{ marginRight: "16px", height: "24px" }} src={walletProviders.handcash.icon} alt="" />
                    <span className="bold">Sign in with HandCash</span>
                </StyledAnchor>
                <Caption margin="60px 0 4px 0" className="small">
                    Don’t have a Bitcoin SV wallet?
                </Caption>
                <StyledCaption
                    className="small"
                    href="https://app.handcash.io/#/authenticate"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Create a Free Wallet
                </StyledCaption>
            </FlexContainer>
            <LoginMobileList />
        </FlexContainer>
    </>
);
export default LoginContent;
