import GameMaintenance from "src/static/Maintenance/Game";
import SiteMaintenance from "src/static/Maintenance/Site";

interface IMaintenanceProps {
    site: boolean;
}

export default function Maintenance({ site = false }: IMaintenanceProps) {
    return <>{site ? <SiteMaintenance /> : <GameMaintenance />}</>;
}


