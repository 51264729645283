import { useMutation, useQuery, useQueryClient } from "react-query";
import BigNumber from "bignumber.js";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { axiosInstance } from "helpers/apiClient";
import { errorToast, successToast } from "src/helpers/toast";
import useTableDeposit from "../GameBalance/useTableDeposit";
import useAppStore from "useAppStore";

async function getPending() {
    const { data } = await axiosInstance.get("/v1/deposit/history", {
        params: {
            asset: "BSV",
            task: "WITHDRAW",
            status: "PENDING",
        },
    });
    return data.data.reduce((accumulator, { amount }) => accumulator.plus(amount), new BigNumber(0));
}

async function getHolding() {
    const { data } = await axiosInstance.get("/v1/deposit/history", {
        params: {
            asset: "BSV",
            task: "WITHDRAW",
            status: "HOLDING",
        },
    });
    return data.data.reduce((accumulator, { amount }) => accumulator.plus(amount), new BigNumber(0));
}

async function withdraw(amount) {
    await axiosInstance.post("/v1/deposit/withdraw", { asset: "BSV", amount });
    return amount;
}

async function cancelWithdraw() {
    const { data: { data } } = await axiosInstance.post("/v1/deposit/withdrawcancel", { asset: "BSV" })
    return data;
}

export default function useCashout() {
    const queryClient = useQueryClient();
    const walletName = useRef(null);
    const currentWallet = useSelector(state => state.wallets.currentWallet);
    const isLoggedIn = useSelector(state => !!state.auth.user);
    const { depositBalance, setBalance } = useTableDeposit();
    const { data: pendingCashout } = useQuery("pendingCashout", getPending, {
        enabled: isLoggedIn,
    });
    const { data: holdingCashout, refetch: refetchHolding } = useQuery("holdingCashout", getHolding, {
        enabled: isLoggedIn,
    });
    const { mutateAsync: cashout, isLoading: isCashingOut } = useMutation(() => withdraw(depositBalance), {
        onSuccess: amount => {
            successToast("Cashout successful");
            setBalance(0);
            queryClient.setQueryData("pendingCashout", amount);
        },
        onError: () => {
            errorToast("Cashout Error. Please refresh your browser and try again.");
        },
    });
    const { mutateAsync: cancelHolding, isLoading: isCancellingHolding } = useMutation(cancelWithdraw, {
        onSuccess: ({ pending_amount: pendingAmount, balance }) => {
            successToast("Holding amount cancelled");
            setBalance(balance);
            queryClient.setQueryData("holdingCashout", new BigNumber(0));
            queryClient.setQueryData("pendingCashout", new BigNumber(pendingAmount));
        },
    });

    useEffect(() => {
        if (currentWallet) {
            walletName.current = currentWallet.name;
        }
    }, [currentWallet]);

    useEffect(() => {
        const { socket } = useAppStore.getState();

        if (!socket.hasListeners("WITHDRAW_COMPLETED")) {
            socket.on("WITHDRAW_COMPLETED", data => {
                const { amount } = JSON.parse(data);
                queryClient.setQueryData("pendingCashout", _cashout => _cashout.minus(amount));
                if (walletName.current !== "handcash") {
                    useAppStore.getState().setAssetBalance(currentBalance => ({
                        BSV: currentBalance.BSV.plus(amount),
                    }));
                }
            });
        }
    }, []);

    return {
        pendingCashout,
        holdingCashout,
        refetchHolding,
        cashout,
        cancelHolding,
        isCashingOut,
        isCancellingHolding,
    };
}
