import create from "zustand";
import BigNumber from "bignumber.js";
import io from "socket.io-client";
import config from "src/config";
import { axiosInstance } from "src/helpers/apiClient";
import { IWalletResponse } from "src/api/responses/wallet";

interface IUser {
    isLoading: boolean;
    data: any | null;
}

interface IAsset {
    selected: string;
    balance: Record<string, BigNumber>;
    isLoading: boolean;
}

interface IState {
    socket: any;
    user: IUser;
    setUserData: (newData: Partial<IUser["data"]>) => void;
    setUserIsLoading: (isLoading: boolean) => void;
    asset: IAsset;
    getAssetBalance: () => Promise<void>;
    setAssetIsLoading: (isLoading: boolean) => void;
    setSelectedAsset: (selected: string) => void;
    setAssetBalance: (updateFunc: (balance: Record<string, BigNumber>) => Record<string, BigNumber>) => void;
    showDepositModal: boolean;
    setShowDepositModal: (showDepositModal: boolean) => void;
    isAdult: boolean | null;
    setIsAdult: (isAdult: boolean | null) => void;
}

const useStore = create<IState>(set => ({
    socket: io(config.chatEndpoint),

    user: { isLoading: false, data: null },
    setUserData: newData => set(state => ({
        user: { ...state.user, data: { ...state.user.data, ...newData } },
    })),
    setUserIsLoading: isLoading => set(state => ({ user: { ...state.user, isLoading } })),

    asset: {
        selected: "BSV",
        balance: { BSV: new BigNumber(0), PGP: new BigNumber(0) },
        isLoading: true,
    },
    getAssetBalance: async () => {
        const { data: { data } } = await axiosInstance.get<IWalletResponse>("/v1/wallets/balance");
        const newBalance = data.reduce(
            (accumulator: Record<string, BigNumber>, { asset, amount }) => ({
                ...accumulator,
                [asset]: new BigNumber(amount),
            }),
            {},
        );
        set(state => ({
            asset: { ...state.asset, isLoading: false, balance: newBalance },
        }));
    },
    setAssetIsLoading: isLoading => set(state => ({ asset: { ...state.asset, isLoading } })),
    setSelectedAsset: selected => set(state => ({ asset: { ...state.asset, selected } })),
    setAssetBalance: updateFunc => set(({ asset }) => ({
        asset: {
            ...asset,
            balance: { ...asset.balance, ...updateFunc(asset.balance) },
        },
    })),

    showDepositModal: false,
    setShowDepositModal: showDepositModal => set({ showDepositModal }),

    isAdult: null,
    setIsAdult: isAdult => set({ isAdult }),
}));

export default useStore;
