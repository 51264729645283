import { memo, useCallback, useRef, Dispatch, SetStateAction } from "react";
import checkIcon from "./assets/Check.svg";
import arrowDownIcon from "./assets/Arrow Down.svg";
import invoiceIcon from "./assets/Invoice.svg";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import {
    StyledLabel,
    StyledMainContainer,
    StyledFlexContainer,
    StyledListContainer,
    StyledItemWrapper,
} from "src/affiliate/components/reusable/drop-down/assets/styles/drop-down.styled";
import useOnClickOutside from "src/affiliate/hooks/use-on-click-outside";
import { IDropDownValue } from "src/core/types/drop-down";

interface IDropDownProps {
    listItems: IDropDownValue[];
    value: IDropDownValue;
    onClickHandler: (item: any) => void;
    trigger: boolean;
    setTrigger: Dispatch<SetStateAction<boolean>>;
    label?: string;
    defaultValue?: string;
    transactionsIcon?: boolean;
    overflowY?: boolean;
}

const DropDown = ({
    listItems,
    value,
    onClickHandler,
    label = "",
    defaultValue = "",
    trigger,
    setTrigger,
    transactionsIcon = false,
    overflowY = false,
}: IDropDownProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(ref, () => setTrigger(false));
    const setTriggerCallBack = useCallback(() => setTrigger(prev => !prev), [setTrigger]);
    return (
        <>
            <FlexContainer ref={ref} width="100%" flexDirection="column">
                {label && <StyledLabel className="small">{label}</StyledLabel>}
                <StyledMainContainer>
                    <StyledFlexContainer
                        border={trigger}
                        trigger={trigger}
                        onClick={() => setTriggerCallBack()}
                        transactionsIcon={transactionsIcon}
                    >
                        {defaultValue ? <p className="small">{defaultValue}</p> : <p>{value.name}</p>}
                        {transactionsIcon ? <img src={invoiceIcon} alt="icon" /> : <img src={arrowDownIcon} alt="icon" />}
                    </StyledFlexContainer>
                    <StyledListContainer maxHeight={trigger} overflowY={overflowY}>
                        {listItems.map(item => (
                            <StyledItemWrapper
                                lastElement={
                                    (listItems[listItems.length - 1].name === item.name ? "0 0 10px 10px" : "")
                                    || (listItems[0].name === item.name ? "10px 10px 0 0" : "")
                                }
                                margin={listItems[listItems.length - 1].name === item.name}
                                checked={value.name === item.name}
                                key={item.name}
                                onClick={() => onClickHandler(item)}
                            >
                                <p className="small" style={{ margin: 0 }}>
                                    {item.name}
                                </p>
                                {value.name === item.name ? <img src={checkIcon} alt="checkIcon" /> : null}
                            </StyledItemWrapper>
                        ))}
                    </StyledListContainer>
                </StyledMainContainer>
            </FlexContainer>
        </>
    );
};

export default memo(DropDown);
