import { useEffect, useState } from "react";
import Input from "src/affiliate/components/reusable/Input";
import BaseModal from "src/components/base-modal/BaseModal";
import apiClient from "src/helpers/apiClient";
import toastNotify from "src/affiliate/utils/toastNotify";
import { StyledForm } from "src/affiliate/components/reusable/assets/styles/create-campaign-modal.styled";
import { FlexItem } from "src/affiliate/styled/layout.styled";
import Button from "src/affiliate/components/reusable/Button";
import { GlobalError, UseFormRegister, UseFormHandleSubmit, FieldValues, UseFormReset, Path } from "react-hook-form";

interface ICreateCampaignModalProps<FormValues extends FieldValues> {
    open: boolean
    onClose: () => void;
    handleSubmit: UseFormHandleSubmit<FormValues, undefined>;
    register: UseFormRegister<FormValues>;
    reset: UseFormReset<FieldValues>;
    errors: Record<string, GlobalError>;
    label: Record<"first" | "second", string>;
    name: Record<"first" | "second", Path<FormValues>>;
    isValid: boolean
    onCampaignCreated?: () => void;
}

const CreateCampaignModal = <FormValues extends FieldValues>({
    open,
    onClose,
    handleSubmit,
    errors,
    register,
    label,
    name,
    isValid,
    reset,
    onCampaignCreated,
}: ICreateCampaignModalProps<FormValues>) => {
    const [defaultCampaignCode, setDefaultCampaignCode] = useState("");
    const onSubmit = value => {
        apiClient()
            .post("/api/v1/affiliate/campaign", value)
            .then(() => {
                onCampaignCreated?.();
                toastNotify("Campaign Created", 3000);
            })
            .catch(() => {
                toastNotify("duplicate campaign name", 3000, true);
            });
        reset();
        onClose();
    };
    useEffect(() => {
        if (open) {
            apiClient()
                .get("/api/v1/affiliate/campaign/code/recommend")
                .then(res => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    const { campaign_code: campaignCode } = res || "";
                    setDefaultCampaignCode(() => campaignCode);
                })
                .catch(e => console.log(e));
        }
    }, [open]);
    return (
        <>
            <BaseModal title="Create Campaign" open={open} onClick={onClose}>
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <FlexItem width="48%" mobileWidth="100%">
                        <Input
                            {...register(name.first)}
                            name={name.first}
                            label={label.first}
                            inputType="text"
                            error={errors}
                            backGroundColor="#12033d"
                            modalInputActive
                        />
                    </FlexItem>
                    <FlexItem width="48%" mobileWidth="100%" mobileMargin="26px 0 0 0">
                        <Input
                            {...register(name.second)}
                            name={name.second}
                            label={label.second}
                            inputType="text"
                            error={errors}
                            backGroundColor="#12033d"
                            modalInputActive
                            value={defaultCampaignCode}
                        />
                    </FlexItem>
                    <FlexItem width="100%" margin="24px 0 0 0" mobileMargin="42px 0 0 0">
                        <Button disabled={!isValid} type="submit">
                            Create Campaign
                        </Button>
                    </FlexItem>
                </StyledForm>
            </BaseModal>
        </>
    );
};

export default CreateCampaignModal;
