import { useRef, RefObject } from "react";
import classNames from "classnames/bind";
import Modal from "src/components/modal/Modal";
import styles from "./assets/styles.scss";
import { useMobileOnly } from "src/helpers/responsive";
import BottomDrawer from "src/components/bottom-drawer/BottomDrawer";
import IconClose from "src/components/modal/assets/close.svg?inline";
import useClickOutside from "src/helpers/useClickOutside";
import usePageScroll from "src/helpers/usePageScroll";
import useLocationChange from "src/helpers/useLocationChange";
import walletProviders from "src/config/walletProviders";
import { getLoginUrl } from "src/redux/modules/auth";

interface IConnectWalletProps {
    isOpen: boolean;
    onClose: () => void;
    // FIXME fix the types to single ref and ensure it still works
    buttonRefs: RefObject<HTMLButtonElement>[];
}

const cx = classNames.bind(styles);

export default function ConnectWallet({ isOpen, onClose, buttonRefs }: IConnectWalletProps) {
    const isMobileOnly = useMobileOnly();
    const popupRef = useRef<HTMLDivElement | null>(null);

    useClickOutside(() => {
        onClose();
    }, [popupRef, ...buttonRefs]);

    usePageScroll(() => onClose());
    useLocationChange(() => onClose());

    const walletOptions = Object.keys(walletProviders).map(provider => (
        <button
            type="button"
            className={cx("wallet-option")}
            key={provider}
            onClick={() => {
                window.location.href = getLoginUrl(provider);
            }}
        >
            <img src={walletProviders[provider].icon} alt={provider} />
            <span className={cx("xlarge", "bold")}>{walletProviders[provider].label}</span>
        </button>
    ));
    const bottomContent = (
        <div className={cx("bottom-content")}>
            <p>Don't have a Bitcoin SV Wallet?</p>
            <a href="https://app.handcash.io/#/authenticate" className={cx("bold")}>
                Create a free BSV Wallet
            </a>
        </div>
    );

    return (
        <>
            {isMobileOnly ? (
                <BottomDrawer isOpen={isOpen} className={cx("connect-wallet-popup")} ref={popupRef}>
                    <div className={cx("header")}>
                        <h4>Connect Wallet</h4>
                        <button type="button" onClick={onClose}>
                            <IconClose />
                        </button>
                    </div>
                    {walletOptions}
                    {bottomContent}
                </BottomDrawer>
            ) : (
                <Modal isOpen={isOpen} onClose={onClose} header="Connect Wallet" modalClassName={cx("modal")}>
                    {walletOptions}
                    <div className={cx("modal-footer")}>{bottomContent}</div>
                </Modal>
            )}
        </>
    );
}

